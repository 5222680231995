import React, { useCallback, useMemo, useState } from 'react';
import { SelectComponent, Value } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import GooglePlacesInput from './GooglePlacesInput';

import * as styles from 'modules/talentOpenings/styles/SearchFilters.styles';
import { useMappedProperties } from '../hooks/useMappedProperties';

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onFilter: (value: string) => void;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export const SearchCompaniesFilters: React.FC<Props> = ({ onFilter }) => {
  const {
    sizeTypeValues,
    industryTypeValues,
    orgTypeValues,
    countryTypeValues,
  } = useMappedProperties();
  const [location, setLocation] = useState<string | null>(null);

  const filterSelectedValue = useMemo(
    () => (values: Value[] | 0 | undefined, selectedValue: string) => {
      if (values === undefined || values === 0) return;

      return values?.find((value) => value.value === selectedValue);
    },
    [],
  );

  const handleValueChange = useCallback((value: string | string[]) => {
    if (typeof value === 'string') {
      onFilter(value);
    }
  }, []);

  return (
    <section css={styles.filters}>
      <GooglePlacesInput
        location={location}
        setLocation={setLocation}
        onValueChange={handleValueChange}
      />

      <SelectComponent
        name="country"
        label={
          <FormattedMessage id="explorePages.searchCompanies.countryLabel" />
        }
        constraints={{}}
        values={countryTypeValues ? countryTypeValues : []}
        customStyles={styles.contractType}
        onValueChange={(value) =>
          handleValueChange(JSON.stringify({ country: value }))
        }
        selectedValue={filterSelectedValue(sizeTypeValues, '')}
      />

      <SelectComponent
        name="size"
        label={<FormattedMessage id="explorePages.searchCompanies.sizeLabel" />}
        constraints={{}}
        values={sizeTypeValues ? sizeTypeValues : []}
        customStyles={styles.contractType}
        onValueChange={(value) =>
          handleValueChange(JSON.stringify({ size: value }))
        }
        selectedValue={filterSelectedValue(sizeTypeValues, '')}
      />

      <SelectComponent
        name="industry"
        label={
          <FormattedMessage id="explorePages.searchCompanies.industryLabel" />
        }
        constraints={{}}
        values={industryTypeValues ? industryTypeValues : []}
        customStyles={styles.contractType}
        onValueChange={(value) =>
          handleValueChange(JSON.stringify({ industry: value }))
        }
        selectedValue={filterSelectedValue(industryTypeValues, '')}
      />

      <SelectComponent
        name="organizationType"
        label={<FormattedMessage id="explorePages.searchCompanies.typeLabel" />}
        constraints={{}}
        values={orgTypeValues ? orgTypeValues : []}
        onValueChange={(value) =>
          handleValueChange(JSON.stringify({ organizationType: value }))
        }
        selectedValue={filterSelectedValue(orgTypeValues, '')}
      />
    </section>
  );
};
