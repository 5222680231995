import React from 'react';
import { GooglePlacesAutocomplete, Tag } from 'modules/app';
import { useReset } from 'modules/onboarding/hooks/useReset';

import { margin } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props {
  location: string | null;
  setLocation: (param: string | null) => void;
  onValueChange: (param: string) => void;
}

const GooglePlacesInput: React.FC<Props> = ({
  location,
  setLocation,
  onValueChange,
}) => {
  const { resetValue, setReset } = useReset();

  const handleDelete = () => {
    setLocation(null);
  };

  const handleSelectedPlace = (selected?: SelectedPlace) => {
    if (!selected) return;

    setLocation(selected.cityCountry);

    onValueChange(
      JSON.stringify({
        xLocation: selected.location.x,
        yLocation: selected.location.y,
      }),
    );
    setReset();
  };

  return (
    <article css={margin.bottom.xxxlrg}>
      <GooglePlacesAutocomplete
        label="search.locationLabel"
        id="location"
        name="location"
        selectedPlace={undefined}
        onSelectedPlace={handleSelectedPlace}
        placeholder="search.locationsPlaceholder"
        isRequired={false}
        reset={resetValue}
        customStyles={margin.bottom.lrg}
      />

      {location && <Tag text={location} onDelete={handleDelete} />}
    </article>
  );
};

export default GooglePlacesInput;
