import { css } from '@emotion/react';

export const wrapper = css`
  max-width: 416px;
`;

export const title = css`
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);
  font-family: var(--font-family-secondary);
  font-weight: normal;
  margin: calc(var(--unit) * 10) 0;
`;

export const text = css`
  color: hsl(var(--color-grayscale-6));
`;
