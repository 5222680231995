import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';
import { breakpoints } from 'style/variables';

export const opening = css`
  margin-right: calc(var(--unit) * 5);
  min-width: ${spacing(416)};
  max-width: ${spacing(416)};

  &:last-of-type {
    margin-right: 0;
  }

  @media ${breakpoints.smallMax} {
    max-width: ${spacing(280)};
    min-width: ${spacing(280)};
  }

  @media ${breakpoints.large} {
    margin-right: calc(var(--unit) * 8);
  }
`;
