import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export interface CompaniesSearchParams {
  search: string;
  xLocation?: number;
  yLocation?: number;
  country?: string;
  industry?: string;
  flexibleWork?: boolean;
  remoteWork?: boolean;
  petFriendlyOffice?: boolean;
  organizationType?: string;
  size?: string;
}

export const initialSearchParams: CompaniesSearchParams = {
  search: '',
  xLocation: undefined,
  yLocation: undefined,
  country: undefined,
  industry: '',
  flexibleWork: undefined,
  remoteWork: undefined,
  petFriendlyOffice: undefined,
  organizationType: '',
  size: '',
};

export const useSearchCompanies = () => {
  const [data, setData] = useState<ExploreCompanies[]>([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState<ApiErrorData | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const http = useHttpClient('/api/v1');

  async function getCompanies(
    info = initialSearchParams,
    page = 1,
    perPage = 6,
  ) {
    const isLocation = Boolean(info.xLocation && info.yLocation);
    const sort = isLocation
      ? { sortBy: 'distance', sort: 'asc' }
      : { sortBy: 'created_at', sort: 'desc' };

    try {
      const { data } = await http.get<CompanySearchResponse>('/companies', {
        headers: isLocation
          ? {
              'x-location-latitude': info.xLocation,
              'x-location-longitude': info.yLocation,
            }
          : {},
        params: {
          page,
          perPage,
          ...sort,
          ...info,
        },
      });
      setData(data.data);
      setPage(data.page);
      setPerPage(data.per_page);
      setLastPage(data.last_page);
      setTotal(data.total);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsPending(false);
    }
  }

  return {
    companies: data,
    searchCompanies: getCompanies,
    error,
    isPending,
    page,
    perPage,
    lastPage,
    total,
  };
};
