import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import * as styles from '../styles/NoResults.styles';

const NoResults: React.FC = () => {
  return (
    <motion.article css={styles.wrapper}>
      <header>
        <h1 css={styles.title}>
          <FormattedMessage id="search.exploreCompanies.noCompaniesHeader" />
        </h1>
      </header>
      <section>
        <p css={styles.text}>
          <FormattedMessage id="search.exploreCompanies.noCompaniesContent" />
        </p>
      </section>
    </motion.article>
  );
};

export default NoResults;
