import useCompaniesAssets from './useCompaniesAssets';
import { useIntl } from 'gatsby-plugin-intl';

export const useMappedProperties = () => {
  const { sizes, industries, types, countries } = useCompaniesAssets();
  const intl = useIntl();

  const mappedSizes = sizes?.map((size) => ({
    value: size,
    label: size,
  }));
  const sizeTypeValues = mappedSizes?.length && [
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
    ...mappedSizes,
  ];

  const mappedIndustries = industries?.map((industry) => ({
    value: industry.code,
    label: industry.name,
  }));
  const industryTypeValues = mappedIndustries?.length && [
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
    ...mappedIndustries,
  ];

  const mappedTypes = types?.map((type) => ({
    value: type,
    label: type,
  }));
  const orgTypeValues = mappedTypes?.length && [
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
    ...mappedTypes,
  ];

  const mappedCountries = countries?.map((country) => ({
    value: country.code,
    label: country.name,
  }));
  const countryTypeValues = mappedCountries?.length && [
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
    ...mappedCountries,
  ];

  return {
    sizeTypeValues,
    industryTypeValues,
    orgTypeValues,
    countryTypeValues,
  };
};
