import { HttpError, Loading, usePreviewContext } from 'modules/app';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { motion } from 'framer-motion';
import { SearchBar } from 'modules/openings/components';
import { debounce } from 'throttle-debounce';
import { SearchCompaniesFilters } from './SearchCompaniesFilter';
import { CompanyCard } from './CompanyCard';
import {
  initialSearchParams as params,
  useSearchCompanies,
} from '../hooks/useSearchCompanies';
import { CompaniesSearchParams } from '../hooks/useSearchCompanies';
import NoResults from './NoResults';

import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as styles from 'modules/talentOpenings/styles/Search.styles';
import * as mainContent from 'modules/app/styles/MainContent.styles';
import { CodeNamePairs } from '../hooks/useCompaniesAssets';

interface Props {
  industries: CodeNamePairs[] | undefined;
  countries: CodeNamePairs[] | undefined;
}

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

export const SearchCompanies: React.FC<Props> = ({ industries, countries }) => {
  const { ctx } = usePreviewContext();
  const { searchCompanies, companies, isPending, error, page, lastPage } =
    useSearchCompanies();
  const [currentPage, setCurrentPage] = useState(page);
  const methods = useForm<CompaniesSearchParams>({
    defaultValues: params,
  });

  const onSearch = debounce(300, (value: string) => {
    searchCompanies({
      ...params,
      search: value,
    });
  });

  const onFilter = useCallback((value: string) => {
    const parsedValue = JSON.parse(value);
    searchCompanies({
      ...params,
      ...parsedValue,
    });
  }, []);

  const onPageChange = (data: { selected: number }) => {
    setCurrentPage(data.selected + 1);
  };

  const getCompanies = useCallback(() => {
    searchCompanies(params, currentPage);
  }, [params, currentPage]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies, params, currentPage]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <motion.section
      {...FADE_IN_MOVE_Y_REGULAR}
      transition={{ duration: 0.4 }}
      css={[
        styles.search,
        ctx.isPreview
          ? mainContent.mainContentPreview
          : mainContent.mainContent,
      ]}
    >
      <header css={styles.searchHeading}>
        <h1>
          <FormattedMessage id="explorePages.searchCompanies.searchLabel" />
        </h1>
        <SearchBar
          onChange={onSearch}
          placeholder="search.exploreCompanies.searchPlaceholder"
          value={params?.search || ''}
          theme="light"
          inputStyles={styles.searchInput}
          containerStyles={styles.searchInputWrapper}
        />
      </header>
      <article css={styles.resultsWrapper}>
        <FormProvider {...methods}>
          <SearchCompaniesFilters
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onFilter={onFilter}
          />
        </FormProvider>

        <Loading
          isLoading={isPending}
          customStyles={margin.top.huge}
          component={
            <>
              {!error && companies ? (
                <>
                  {Boolean(companies?.length) && (
                    <motion.section
                      {...FADE_IN_MOVE_Y_REGULAR}
                      transition={{ duration: 0.4 }}
                    >
                      <motion.section layout css={styles.result}>
                        {companies?.map((company) => {
                          return (
                            <CompanyCard
                              key={company.handle}
                              company={company}
                              customStyles={styles.opening}
                              industries={industries}
                              countries={countries}
                            />
                          );
                        })}
                      </motion.section>

                      {lastPage > 1 && (
                        <React.Suspense fallback={null}>
                          <Pagination
                            initialPage={currentPage - 1}
                            pageCount={lastPage}
                            onPageChange={onPageChange}
                          />
                        </React.Suspense>
                      )}
                    </motion.section>
                  )}

                  {!Boolean(companies.length) && (
                    <motion.div
                      css={styles.noResults}
                      {...FADE_IN_MOVE_Y_REGULAR}
                      transition={{ duration: 0.4 }}
                    >
                      <NoResults />
                    </motion.div>
                  )}
                </>
              ) : (
                <div role="presentation">
                  <HttpError error={error} />
                </div>
              )}
            </>
          }
        />
      </article>
    </motion.section>
  );
};
