import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useEffect, useState } from 'react';

export interface CodeNamePairs {
  code: string;
  name: string;
}

export interface AssetType {
  careerPathOptions: {
    career_grow_paths: CodeNamePairs[];
    hr_session_conductor: CodeNamePairs[];
    hr_session_frequency: CodeNamePairs[];
    leader_session_conductor: CodeNamePairs[];
    leader_session_frequency: CodeNamePairs[];
    person_in_charge: CodeNamePairs[];
    topics: CodeNamePairs[];
  };
  countries: Country[];
  industries: CodeNamePairs[];
  sizes: string[];
  teamBondingOptions: {
    team_building_frequency: CodeNamePairs[];
  };
  types: string[];
}

const useCompaniesAssets = () => {
  const [data, setData] = useState<AssetType>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();

  const http = useHttpClient('/api/v1/assets');

  async function getCompanieAssets() {
    setIsLoading(true);
    try {
      const { data } = await http.get<AssetType>('/companies');
      setData(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCompanieAssets();
  }, []);

  return {
    data,
    error,
    isLoading,
    countries: data?.countries,
    industries: data?.industries,
    sizes: data?.sizes,
    types: data?.types,
    careerPathOptions: data?.careerPathOptions,
  };
};

export default useCompaniesAssets;
