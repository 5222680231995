import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import React from 'react';

interface Props {
  path: string;
  text: string;
}

export const HeaderLink: React.FC<Props> = ({ path, text }) => {
  return (
    <Link
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return {
          className:
            isCurrent || isPartiallyCurrent
              ? 'header__link header__link--active'
              : 'header__link',
        };
      }}
      to={path}
    >
      <FormattedMessage id={text} />
    </Link>
  );
};
