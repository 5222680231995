import { css } from '@emotion/react';

export const header = css`
  background-color: hsl(var(--color-text-1));
  color: hsl(var(--color-white-11));
  padding: calc(var(--unit) * 20) 0;

  h1 {
    margin-bottom: var(--spacing-huge);
  }

  h2 {
    margin-bottom: calc(var(--unit) * 20);
  }

  .header__link {
    font-family: var(--font-family-secondary);
    font-weight: normal;
    font-size: var(--font-size-huge);
    line-height: var(--line-height-huge);
    color: hsl(var(--color-borderDark-5));

    &[aria-current],
    &:hover {
      color: hsl(var(--color-grayscale-1));
    }
  }

  .header__link--active {
    color: hsl(var(--color-grayscale-1));
  }
`;

export const heading = css`
  text-transform: uppercase;
  color: hsl(var(--color-grayscale-6));
`;

export const linkContainerWrapper = css`
  overflow: hidden;
`;

export const linkContainer = css`
  display: flex;
  align-items: center;
  column-gap: var(--spacing-xxlrg);
  margin-bottom: calc(var(--unit) * 20);
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
