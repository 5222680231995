import { CookieConsent, PublicLayout } from 'modules/app';
import Seo from 'modules/app/components/Seo';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';
import { ExploreCompanies } from 'modules/explore/ExploreCompanies';
import React from 'react';

const Companies: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Browse Companies - Roango"
        description="Discover companies that fit your skillset and cultural preferences, connect and engage with them directly, without a middleman."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <ExploreCompanies />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Companies;
