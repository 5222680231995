import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Slider } from 'modules/app/components/Slider';
import { CompanyCard } from './components/CompanyCard';
import { Layout } from './components/Layout';
import { Header } from './components/Header';
import { HttpError } from 'modules/app/http/components/HttpError';
import { SearchCompanies } from './components/SearchCompanies';
import {
  initialSearchParams,
  useSearchCompanies,
} from './hooks/useSearchCompanies';

import * as styles from './styles/Root.styles';
import * as openingBox from './styles/OpeningBox.styles';
import useCompaniesAssets from './hooks/useCompaniesAssets';

export const ExploreCompanies: React.FC<RouteComponentProps> = () => {
  const { companies, searchCompanies, error } = useSearchCompanies();
  const { industries, countries } = useCompaniesAssets();

  const recentCompanies: ExploreCompanies[] = companies.slice(0, 10);

  useEffect(() => {
    searchCompanies(initialSearchParams, 1, 10);
  }, []);

  return (
    <Layout>
      <Header>
        {!error && companies.length ? (
          <Slider>
            {recentCompanies?.map((company, index) => (
              <CompanyCard
                industries={industries}
                countries={countries}
                key={index}
                company={company}
                theme="dark"
                customStyles={openingBox.opening}
              />
            ))}
          </Slider>
        ) : (
          <section css={styles.wrapper}>
            <HttpError error={error} />
          </section>
        )}
      </Header>
      <SearchCompanies industries={industries} countries={countries} />
    </Layout>
  );
};
