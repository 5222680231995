import { css } from '@emotion/react';

export const root = css`
  min-height: 100vh;
`;

export const wrapper = css`
  max-width: 1312px;
  margin: 0 auto;
  padding-left: var(--spacing-xlrg);
  padding-right: var(--spacing-xlrg);
  box-sizing: content-box;
`;
