import { Navbar, Footer } from 'modules/app';
import React from 'react';

import * as styles from '../styles/Root.styles';

export const Layout: React.FC = ({ children }) => {
  return (
    <article css={styles.root}>
      <Navbar negative={true} />
      {children}
      <Footer />
    </article>
  );
};
