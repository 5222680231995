import React from 'react';
import { Avatar, Heading } from 'modules/app';
import { motion } from 'framer-motion';
import { findCodeNamePair } from '../utils/findCodeNamePair';

import * as styles from 'modules/app/styles/OpeningBox.styles';

import { Link } from 'gatsby';
import { CodeNamePairs } from '../hooks/useCompaniesAssets';

interface Props {
  company: ExploreCompanies;
  theme?: 'light' | 'dark';
  customStyles?: SerializedStyles | SerializedStyles[];
  industries: CodeNamePairs[] | undefined;
  countries: CodeNamePairs[] | undefined;
}

export const CompanyCard: React.FC<Props> = ({
  company,
  theme = 'light',
  customStyles,
  industries,
  countries,
}) => {
  const avatarCustomStyles = company.iconPicture
    ? styles.avatar
    : styles.avatarWithBackground;

  return (
    <motion.article layout css={[styles.rootTheme(theme), customStyles]}>
      <header>
        <Link
          css={theme === 'light' ? styles.nameLight : styles.name}
          to={`/company/${company.handle}`}
        >
          <Avatar
            customStyles={avatarCustomStyles}
            name={company.name}
            imagePath={company.iconPicture?.large}
            isShadow={theme === 'dark' ? false : true}
          />
          <p>{company.name}</p>
        </Link>
      </header>
      <section>
        <header
          css={theme === 'light' ? styles.openingNameLight : styles.openingName}
        >
          <Link to={`/company/${company.handle}`}>
            <Heading as="h3" size="fluidLarge">
              {findCodeNamePair(company.industry, industries)?.name}
            </Heading>
          </Link>
        </header>
        <section css={styles.openingInfo}>
          {company.address !== null ? (
            <p>{company.address}</p>
          ) : (
            <p>{findCodeNamePair(company.country, countries)?.name}</p>
          )}

          {company.companyOpeningsCount > 0 ? (
            <p>
              {company.companyOpeningsCount.toString()}{' '}
              {company.companyOpeningsCount === 1 ? 'opening' : 'openings'}
            </p>
          ) : null}
          {company.size !== null ? (
            <p>
              {company.size === '0-1'
                ? `${company.size} employee`
                : `${company.size} employees`}
            </p>
          ) : null}
        </section>
      </section>
    </motion.article>
  );
};
