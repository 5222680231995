import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading } from 'modules/app/components/Heading';
import React from 'react';

import * as styles from '../styles/Root.styles';
import * as header from '../styles/Header.styles';
import { HeaderLink } from './HeaderLink';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  isOpenings?: boolean;
}

export const Header: React.FC<Props> = ({ children, isOpenings }) => {
  return (
    <motion.header layout css={header.header}>
      <motion.section layout="position" css={styles.wrapper}>
        <Heading as="h1" size="tiny" css={header.heading}>
          <FormattedMessage id="explorePages.title" />
        </Heading>
        <div css={header.linkContainerWrapper}>
          <div css={header.linkContainer}>
            <HeaderLink
              path="/explore/openings"
              text="explorePages.openingsLink"
            />
            <HeaderLink
              path="/explore/companies"
              text="explorePages.companiesLink"
            />
          </div>
        </div>
        <Heading as="h2" size="fluidXxLarge">
          <FormattedMessage
            id={
              isOpenings
                ? 'explorePages.openingsTitle'
                : 'explorePages.companiesTitle'
            }
          />
        </Heading>
      </motion.section>
      <motion.section
        {...FADE_IN_MOVE_Y_REGULAR}
        transition={{ duration: 0.3, delay: 0.4 }}
        layout="position"
      >
        {children}
      </motion.section>
    </motion.header>
  );
};
